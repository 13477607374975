<template>
  <el-container class="home-container">
    <el-header style="height: 50px;">
      <div class="head_left">
        <div class="system_name">消防物联网监控云平台后台管理</div>
      </div>
      <div class="head_right">
        <div @click="loginOut"><span>退出登录</span> </div>
      </div>
    </el-header>
    <el-container>
      <el-aside style="width: 220px;">
        <Sidebar></Sidebar>
      </el-aside>
      <el-main class="setting_main">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Sidebar from '../../components/sidebar.vue'

export default {
  components: {
    Sidebar
  },
  data () {
    return {
    }
  },
  created () {
    this.$router.push({path: '/User'})
  },
  methods: {
    loginOut () {
      this.$router.push('/Login')
      window.sessionStorage.setItem('loginInfo', '')
      window.localStorage.setItem('loginInfo', '')
      window.localStorage.setItem('token', '')
    },
  }
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: 100%;
  background: #F0F3F8;
}
.el-header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 40px;
  box-sizing: border-box;
  position: relative;
  background: #195CF7;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.system_name {
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  font-family: 'PingFang SC';
  font-weight: 400;
  color: #FFFFFF;
  opacity: 1;
}
.head_right {
  font-size: 16px;
  font-family: 'PingFang SC';
  font-weight: 400;
  line-height: 23px;
  color: #FFFFFF;
  opacity: 1;
  cursor: pointer;
}
.el-main {
  position: absolute;
  top: 50px;
  left:230px;
  right: 0; 
  bottom: 0;
}
</style>
