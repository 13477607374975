<template>
  <div class="sidebar">
      <el-menu class="sidebar-el-menu" :default-active="onRoutes" background-color="#FFFFFF"
          text-color="#333333" active-text-color="#333333" unique-opened router @select="selectMenu">
          <template v-for="item in items">
              <template v-if="item.subs">
                  <el-submenu :index="item.index" :key="item.index">
                      <template slot="title">
                        <div class="flexAO">
                          <img :src="item.icon" style="width: 26px; height: 26px;margin-right: 20px;" />
                          <span>{{ item.title }}</span>
                        </div>
                      </template>
                      <template v-for="subItem in item.subs">
                          <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                              <template slot="title">{{ subItem.title }}</template>
                              <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">
                                  {{ threeItem.title }}</el-menu-item>
                          </el-submenu>
                          <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{ subItem.title }}</el-menu-item>
                      </template>
                  </el-submenu>
              </template>
              <template v-else>
                  <el-menu-item :index="item.index" :key="item.index">
                      <div class="flexAO">
                        <img :src="item.icon" style="width: 26px; height: 26px;margin-right: 20px;" />
                        <span>{{ item.title }}</span>
                      </div>
                  </el-menu-item>
              </template>
          </template>
      </el-menu>
  </div>
</template>

<script>
import User from '../assets/img/item1.png'
import Data from '../assets/img/item2.png'
import Version from '../assets/img/item3.png'
import Log from '../assets/img/item4.png'

export default {
  data () {
    return {
      items: [
        {
          icon: User,
          index: 'User',
          title: '用户管理'
        },
        {
          icon: Data,
          index: 'Data',
          title: '数据管理'
        },
        {
          icon: Version,
          index: 'Version',
          title: '版本管理',
          subs: [
            {
              index: 'SyVersion',
              title: '系统版本'
            },
            {
              index: 'VerInform',
              title: '版本通知'
            }
          ]
        },
        {
          icon: Log,
          index: 'Log',
          title: '日志管理'
        },
      ],
      checkedMenu: '',
    }
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace('/', '')
    }
  },
  watch: {
    
  },
  created () {
    this.checkedMenu = 'User'
  },
  methods: {
    selectMenu(index) {
      this.checkedMenu = index
    }
  }
}
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 50px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 220px;
}
.sidebar > ul {
  height: 100%;
}
.sidebar .el-menu--inline .el-menu-item {
  padding-left: 70px !important;
}
.el-menu-item {
  height: 70px !important;
  line-height: 70px !important;
}
.el-menu-item.is-active{
  background-color: #E7EEFF !important;
}
</style>
